import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import Button from '@/components_new/Button.vue'
import FennecCoinsImg from '@/components_new/PromoItems/images/fennec-coin-silver.webp'
import PromoBanner from '@/components_new/PromoItems/PromoBanner.vue'
import { useUserStore } from '@/store/user-store/user-store'
import { storeToRefs } from 'pinia'


export default /*@__PURE__*/_defineComponent({
  __name: 'PromoSignInVertical',
  setup(__props) {

const userStore = useUserStore()
const { isAuthorized, isUserInfoLoaded } = storeToRefs(userStore)

return (_ctx: any,_cache: any) => {
  return (!_unref(isAuthorized) && _unref(isUserInfoLoaded))
    ? (_openBlock(), _createBlock(PromoBanner, {
        key: 0,
        image: { src: _unref(FennecCoinsImg), alt: _ctx.$tr('promoItems.sign_in_alt') },
        description: _ctx.$tr('promoItems.sign_in_desc'),
        direction: "column",
        title: _ctx.$tr('promoItems.sign_in_title')
      }, {
        default: _withCtx(({ handleClick }) => [
          _createVNode(Button, {
            size: "l",
            color: "golden",
            interfaceTarget: "open-sign-in-modal",
            onClick: ($event: any) => (handleClick('sign_in'))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$tr('promoItems.sign_in_btn')), 1)
            ]),
            _: 2
          }, 1032, ["onClick"])
        ]),
        _: 1
      }, 8, ["image", "description", "title"]))
    : _createCommentVNode("", true)
}
}

})