import { ref } from 'vue'
import { defineStore } from 'pinia'
import type { MainCategoriesType, ShortGameZipType } from '@/types'
import { getMainPageGamesByCategories } from '@/utils/requests/games'
import { useAppStore } from '@/store/app-store'
import { ShortCategory } from '@/store/categories-store'
import { shuffle, zipAPIGame } from '@/utils/helpers'
import { useI18nAddon } from '@/i18n/i18n'
import { useUrl } from '@/composables/url-helpers'
import { RouteLocationRaw } from 'vue-router'

export type MainCategoryListZipType = Omit<ShortCategory, 'location'> & {
    location: RouteLocationRaw | undefined
    games?: ShortGameZipType[]
}

export const useHomepageStore = defineStore('homepage', () => {
    const categoriesWithGames = ref<Record<string, MainCategoryListZipType[]>>({})
    const appStore = useAppStore()
    const { getCategoryRoute } = useUrl()
    const i18nAddon = useI18nAddon()

    async function loadCategoriesWithGames(): Promise<void> {
        const currentLocale = i18nAddon.currentLocale.value
        if (categoriesWithGames.value[currentLocale]) {
            return
        }
        const currentPlatform = appStore.platform

        const data = await getMainPageGamesByCategories({
            platform: currentPlatform,
            locale: currentLocale,
        })

        const { categories } = data
        categoriesWithGames.value[currentLocale] = categories
            .map(({ id, hru, title, games }) => ({
                id: id as MainCategoriesType,
                title,
                location: id === 'random' ? undefined : getCategoryRoute({ hru, type: 'category' }),
                games: shuffle(games.map(zipAPIGame)),
            }))
            .filter((cat) => cat.games.length > 0)
    }

    return {
        categoriesWithGames,
        loadCategoriesWithGames,
    }
})
