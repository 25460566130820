<template>
    <PromoBanner
        v-if="isAuthorized && isUserInfoLoaded"
        :description="$tr('promoItems.invite_desc')"
        direction="row"
        title="10 000"
        :buttonPosition="props.buttonPosition"
        textAlign="left"
        icon="silver_fennec_coin_small"
        :image="{ src: FriendImg, alt: $tr('promoItems.invite_alt') }"
    >
        <template #default="{ handleClick }">
            <Button
                interfaceTarget="open-invite-modal"
                size="l"
                @click="handleClick('invite')"
            >
                {{ $tr('promoItems.invite_btn') }}
            </Button>
        </template>
    </PromoBanner>
</template>
<script setup lang="ts">
import Button from '@/components_new/Button.vue'
import FriendImg from '@/components_new/PromoItems/images/friend.webp'
import PromoBanner from '@/components_new/PromoItems/PromoBanner.vue'
import { useUserStore } from '@/store/user-store/user-store'
import { storeToRefs } from 'pinia'

interface PromoInviteHorizontalProps {
    buttonPosition?: 'right' | 'bottom'
}

const props = withDefaults(defineProps<PromoInviteHorizontalProps>(), {
    buttonPosition: 'right',
})

const userStore = useUserStore()
const { isAuthorized, isUserInfoLoaded } = storeToRefs(userStore)
</script>
