import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { key: 0 }

import { storeToRefs } from 'pinia'
import Typography from '@/components_new/Typography.vue'
import TaskCard from '@/components_new/TaskItems/TaskCard.vue'
import CardCarousel from '@/components_new/CardCarousel.vue'
import Button from '@/components_new/Button.vue'
import { useTasksStore } from '@/components_new/TaskItems/store'
import { useUserStore } from '@/store/user-store/user-store'


export default /*@__PURE__*/_defineComponent({
  __name: 'Tasks',
  setup(__props) {

const tasksStore = useTasksStore()
const userStore = useUserStore()
const { tasks, tasksError, showTasks, allTasksClaimed } = storeToRefs(tasksStore)
const { isAuthorized } = storeToRefs(userStore)

return (_ctx: any,_cache: any) => {
  return (_unref(isAuthorized) && (_unref(showTasks) || (_unref(allTasksClaimed) && _ctx.$slots.default)))
    ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
        (_unref(tasksError))
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(_ctx.$style.error_container)
            }, [
              _createVNode(Typography, {
                type: "label",
                size: "m",
                class: _normalizeClass(_ctx.$style.error_title),
                accent: ""
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$tr('tasks.error_title')), 1)
                ]),
                _: 1
              }, 8, ["class"]),
              _createVNode(Button, {
                class: _normalizeClass(_ctx.$style.error_button),
                interfaceTarget: "reload-tasks",
                size: "l",
                color: "carbon-400",
                visual: "fill",
                onClick: _unref(tasksStore).onReloadClick
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$tr('tasks.error_button')), 1)
                ]),
                _: 1
              }, 8, ["class", "onClick"])
            ], 2))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              (!_unref(allTasksClaimed))
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createVNode(Typography, {
                      is: "h2",
                      type: "header",
                      class: _normalizeClass(_ctx.$style.tasks_title),
                      size: "xs",
                      responsive: false
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$tr('tasks.title')), 1)
                      ]),
                      _: 1
                    }, 8, ["class"]),
                    _createVNode(CardCarousel, {
                      items: _unref(tasks),
                      "data-interface-section": "tasks-carousel"
                    }, {
                      default: _withCtx(({ item: task, className }) => [
                        _createVNode(TaskCard, {
                          task: task,
                          class: _normalizeClass({ [className]: true }),
                          onClaimTask: _unref(tasksStore).onTaskClaimed,
                          onTaskStart: _unref(tasksStore).onTaskStart
                        }, null, 8, ["task", "class", "onClaimTask", "onTaskStart"])
                      ]),
                      _: 1
                    }, 8, ["items"])
                  ], 64))
                : _renderSlot(_ctx.$slots, "default", { key: 1 })
            ], 64))
      ]))
    : _createCommentVNode("", true)
}
}

})