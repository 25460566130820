<template>
    <PromoBanner
        v-if="isUserInfoLoaded && shouldShowAddGameBanner"
        :image="{ src: AddGameImg, alt: $tr('promoItems.add_game_alt') }"
        :description="$tr('promoItems.add_game_desc')"
        hasCloseBtn
        direction="row"
        :link="{ externalTo: DEVELOPERS_LANDPAGE_PATH, target: '_blank' }"
        :title="$tr('promoItems.add_game_title')"
        textAlign="left"
        @closePromo="promoStore.hideAddGameBanner"
    />
</template>
<script setup lang="ts">
import AddGameImg from '@/components_new/PromoItems/images/add-game.webp'
import PromoBanner from '@/components_new/PromoItems/PromoBanner.vue'
import { DEVELOPERS_LANDPAGE_PATH } from '@/router/navigation-constants'
import { useUserStore } from '@/store/user-store/user-store'
import { storeToRefs } from 'pinia'
import { usePromoStore } from '@/components_new/PromoItems/store'

const userStore = useUserStore()
const promoStore = usePromoStore()
const { isUserInfoLoaded } = storeToRefs(userStore)
const { shouldShowAddGameBanner } = storeToRefs(promoStore)
</script>
