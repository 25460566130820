import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

import { computed } from 'vue'
import type {
    DailyTaskDto,
    GameTaskDto,
    RandomTaskDto,
    ReferralTaskDto,
    ShortGameType,
    TaskDto,
    TaskStatus,
    TaskType,
} from '@/types'
import { storeToRefs } from 'pinia'
import { useCategoriesStore } from '@/store/categories-store'
import Button from '@/components_new/Button.vue'
import Typography from '@/components_new/Typography.vue'
import CardContainer from '@/components_new/CardContainer.vue'
import Icon from '@/components_new/Icon/Icon.vue'
import randomGameImage from '@/components_new/TaskItems/images/random_game.webp'
import dailyBonusImage from '@/components_new/TaskItems/images/daily_bonus.webp'
import referralImage from '@/components_new/TaskItems/images/referral.webp'
import GameCard from '@/components_new/GameCard.vue'
import BaseLink from '@/components_new/BaseLink.vue'
import { useTr } from '@/composables/use-tr'
import { RouteLocationRaw } from 'vue-router'
import { ROUTE_NAMES } from '@/router/constants'

interface TaskCardProps {
    task?: TaskDto
}

type TaskCard = {
    id: string
    status: TaskStatus
    coins: number
    imgSrc: string
    imgAlt?: string
    description: string
    buttonLabel: string
    gameLink?: RouteLocationRaw
    srcsetOn: boolean
    game?: ShortGameType
    type: TaskType
    min?: number
}


export default /*@__PURE__*/_defineComponent({
  __name: 'TaskCard',
  props: {
    task: {}
  },
  emits: ["claimTask", "taskStart"],
  setup(__props: any, { emit: __emit }) {

const props = __props

const emit = __emit
const tr = useTr()

const categoriesStore = useCategoriesStore()

const { randomGameHRU } = storeToRefs(categoriesStore)

function getReferralCard(task: ReferralTaskDto): TaskCard {
    const {
        status,
        coins,
        task_id,
        type,
        settings: { invitations_not_claimed },
    } = task
    const coinsPerFriend = invitations_not_claimed > 1 ? Math.floor(coins / invitations_not_claimed) : coins
    const description =
        invitations_not_claimed > 1
            ? tr('tasks.collect_invite_many', {
                  friends: String(invitations_not_claimed),
                  coins: String(coinsPerFriend),
              })
            : tr('tasks.collect_invite', { coins: String(coinsPerFriend) })
    return {
        status,
        coins,
        id: task_id,
        type,
        srcsetOn: false,
        imgSrc: referralImage,
        imgAlt: tr('tasks.collect_invite_alt'),
        buttonLabel: tr('tasks.claim'),
        description,
    }
}

function getDailyBonusCard(card: DailyTaskDto): TaskCard {
    const { status, coins, task_id, type } = card
    return {
        status,
        coins,
        id: task_id,
        type,
        srcsetOn: false,
        imgSrc: dailyBonusImage,
        imgAlt: tr('tasks.daily_bonus_alt'),
        buttonLabel: tr('tasks.claim'),
        description: tr('tasks.daily_bonus_description'),
    }
}

function getRandomGameCard(card: RandomTaskDto): TaskCard {
    const { status, coins, settings, task_id, type } = card
    const min = Math.ceil(settings.seconds_to_play / 60)
    return {
        status,
        type,
        id: task_id,
        coins,
        srcsetOn: false,
        imgSrc: randomGameImage,
        buttonLabel: tr('tasks.play'),
        description: tr('tasks.random_game_description', { min: String(min) }),
        ...(randomGameHRU.value && {
            gameLink: { name: ROUTE_NAMES.GAME_PAGE, params: { game: randomGameHRU.value } },
        }),
    }
}

function getGameCard(card: GameTaskDto): TaskCard {
    const {
        status,
        coins,
        settings: { game, seconds_to_play },
        task_id,
        type,
    } = card
    const min = Math.ceil(seconds_to_play / 60)
    return {
        status,
        type,
        coins,
        id: task_id,
        srcsetOn: true,
        imgAlt: game.title,
        game,
        imgSrc: game.preview,
        description: game.title,
        buttonLabel: tr('tasks.button_label', { min: String(min) }),
        min,
        ...(randomGameHRU.value && { gameLink: { name: ROUTE_NAMES.GAME_PAGE, params: { game: game.hru } } }),
    }
}

const task = computed(() => {
    if (!props.task) {
        return undefined
    }

    if (props.task.type === 'VISIT_EVERYDAY') {
        return getDailyBonusCard(props.task)
    }

    if (props.task.type === 'RANDOM_GAME') {
        return getRandomGameCard(props.task)
    }

    if (props.task.type === 'PLAY_GAME') {
        return getGameCard(props.task)
    }
    return getReferralCard(props.task)
})

const isTaskClaimed = computed(() => task.value?.status === 'CLAIMED')

const onClaim = (taskId: TaskCard['id']) => emit('claimTask', taskId)
const onAction = (taskId: TaskCard['id']) => emit('taskStart', taskId)

return (_ctx: any,_cache: any) => {
  return (task.value)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(_ctx.$style.card)
      }, [
        _createElementVNode("div", {
          class: _normalizeClass([_ctx.$style.content_wrapper, { [_ctx.$style.disabled]: isTaskClaimed.value }])
        }, [
          _createElementVNode("div", null, [
            (task.value.game && !isTaskClaimed.value)
              ? (_openBlock(), _createBlock(GameCard, {
                  key: 0,
                  appearance: "medium",
                  game: task.value.game,
                  responsive: "",
                  "data-interface-target": "game",
                  preview: task.value.imgSrc
                }, null, 8, ["game", "preview"]))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  (task.value.gameLink && !isTaskClaimed.value)
                    ? (_openBlock(), _createBlock(BaseLink, {
                        key: 0,
                        to: task.value.gameLink,
                        target: "_blank",
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (onAction(task.value.id)))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(CardContainer, {
                            appearance: "medium",
                            "data-interface-target": "game",
                            alt: task.value.imgAlt,
                            responsive: "",
                            src: task.value.imgSrc,
                            srcsetOn: task.value.srcsetOn
                          }, null, 8, ["alt", "src", "srcsetOn"])
                        ]),
                        _: 1
                      }, 8, ["to"]))
                    : (_openBlock(), _createBlock(CardContainer, {
                        key: 1,
                        appearance: "medium",
                        alt: task.value.imgAlt,
                        responsive: "",
                        src: task.value.imgSrc,
                        srcsetOn: task.value.srcsetOn
                      }, null, 8, ["alt", "src", "srcsetOn"]))
                ], 64))
          ]),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.$style.fennecs)
          }, [
            _createVNode(Icon, { name: "silver_fennec_coin_small" }),
            _createVNode(Typography, {
              type: "header",
              size: "s",
              active: "",
              responsive: false
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(task.value.coins), 1)
              ]),
              _: 1
            })
          ], 2),
          _createVNode(Typography, {
            type: "paragraph",
            size: "s",
            class: _normalizeClass(_ctx.$style.description),
            responsive: false
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(task.value.description), 1)
            ]),
            _: 1
          }, 8, ["class"])
        ], 2),
        (task.value.status === 'ACTIVE')
          ? (_openBlock(), _createBlock(Button, {
              key: 0,
              size: "m",
              interfaceTarget: "active-task",
              to: task.value.gameLink,
              onClick: _cache[1] || (_cache[1] = ($event: any) => (onAction(task.value.id)))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(task.value.buttonLabel), 1)
              ]),
              _: 1
            }, 8, ["to"]))
          : (_openBlock(), _createBlock(Button, {
              key: 1,
              size: "m",
              color: "golden",
              interfaceTarget: isTaskClaimed.value ? 'claimed-task' : 'claim-task',
              disabled: isTaskClaimed.value,
              onClick: _cache[2] || (_cache[2] = ($event: any) => (onClaim(task.value.id)))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(isTaskClaimed.value ? _ctx.$tr('tasks.claimed') : _ctx.$tr('tasks.claim')), 1)
              ]),
              _: 1
            }, 8, ["interfaceTarget", "disabled"]))
      ], 2))
    : _createCommentVNode("", true)
}
}

})