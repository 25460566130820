import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import AddGameImg from '@/components_new/PromoItems/images/add-game.webp'
import PromoBanner from '@/components_new/PromoItems/PromoBanner.vue'
import { DEVELOPERS_LANDPAGE_PATH } from '@/router/navigation-constants'
import { useUserStore } from '@/store/user-store/user-store'
import { storeToRefs } from 'pinia'
import { usePromoStore } from '@/components_new/PromoItems/store'


export default /*@__PURE__*/_defineComponent({
  __name: 'PromoAddGameHorizontalShort',
  setup(__props) {

const userStore = useUserStore()
const promoStore = usePromoStore()
const { isUserInfoLoaded } = storeToRefs(userStore)
const { shouldShowAddGameBanner } = storeToRefs(promoStore)

return (_ctx: any,_cache: any) => {
  return (_unref(isUserInfoLoaded) && _unref(shouldShowAddGameBanner))
    ? (_openBlock(), _createBlock(PromoBanner, {
        key: 0,
        image: { src: _unref(AddGameImg), alt: _ctx.$tr('promoItems.add_game_alt') },
        description: _ctx.$tr('promoItems.add_game_desc'),
        hasCloseBtn: "",
        direction: "row",
        link: { externalTo: _unref(DEVELOPERS_LANDPAGE_PATH), target: '_blank' },
        title: _ctx.$tr('promoItems.add_game_title'),
        textAlign: "left",
        onClosePromo: _unref(promoStore).hideAddGameBanner
      }, null, 8, ["image", "description", "link", "title", "onClosePromo"]))
    : _createCommentVNode("", true)
}
}

})