import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import Button from '@/components_new/Button.vue'
import AddGameImg from '@/components_new/PromoItems/images/add-game.webp'
import PromoBanner from '@/components_new/PromoItems/PromoBanner.vue'
import { DEVELOPERS_LANDPAGE_PATH } from '@/router/navigation-constants'
import { useUserStore } from '@/store/user-store/user-store'
import { storeToRefs } from 'pinia'
import { usePromoStore } from '@/components_new/PromoItems/store'

interface PromoSignInHorizontalProps {
    buttonPosition?: 'right' | 'bottom'
}


export default /*@__PURE__*/_defineComponent({
  __name: 'PromoAddGameHorizontal',
  props: {
    buttonPosition: { default: 'right' }
  },
  setup(__props: any) {

const props = __props

const userStore = useUserStore()
const promoStore = usePromoStore()
const { isUserInfoLoaded } = storeToRefs(userStore)
const { shouldShowAddGameBanner } = storeToRefs(promoStore)

return (_ctx: any,_cache: any) => {
  return (_unref(isUserInfoLoaded) && _unref(shouldShowAddGameBanner))
    ? (_openBlock(), _createBlock(PromoBanner, {
        key: 0,
        image: { src: _unref(AddGameImg), alt: _ctx.$tr('promoItems.add_game_alt') },
        description: _ctx.$tr('promoItems.add_game_desc'),
        direction: "row",
        title: _ctx.$tr('promoItems.add_game_title'),
        buttonPosition: props.buttonPosition,
        textAlign: "left",
        hasCloseBtn: "",
        onClosePromo: _unref(promoStore).hideAddGameBanner
      }, {
        default: _withCtx(() => [
          _createVNode(Button, {
            externalTo: _unref(DEVELOPERS_LANDPAGE_PATH),
            target: "_blank",
            size: "l",
            interfaceTarget: "navigation-link",
            external: ""
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$tr('promoItems.add_game_btn')), 1)
            ]),
            _: 1
          }, 8, ["externalTo"])
        ]),
        _: 1
      }, 8, ["image", "description", "title", "buttonPosition", "onClosePromo"]))
    : _createCommentVNode("", true)
}
}

})