<template>
    <Layout>
        <main :class="$style.page">
            <PromoSignInHorizontal :class="$style.tablet" />
            <Tasks :class="$style.tablet" />
            <Tasks :class="$style.mobile">
                <PromoInviteVertical />
            </Tasks>
            <template
                v-for="(category, index) in preparedCategories"
                :key="category.id"
            >
                <GameCardCarousel
                    :data-interface-section="category.id"
                    :title="category.title"
                    :link="category.location"
                    :games="category.games"
                    :big="category.isBig"
                    :firstItemTabletPlusBig="category.firstItemBig"
                    :lastItemLink="category.location"
                    :promo="index === 1 ? { type: 'add_game' } : undefined"
                    :eagerThumbs="index < 3 ? 3 : 0"
                />
                <PromoSignInVertical
                    v-if="index === 1"
                    :class="$style.mobile"
                />
                <PromoInviteHorizontal
                    v-if="index === 0"
                    :class="$style.tablet"
                />
                <template v-if="showAd && index === 1">
                    <AdBlock
                        type="leaderboard"
                        :class="$style.tabletPlus"
                        :emptyClass="$style.emptyRow"
                    />
                    <AdBlock
                        type="catalog_mobile"
                        :class="$style.mobilePortrait"
                        :emptyClass="$style.emptyRow"
                    />
                </template>
                <PromoAddGameHorizontalShort
                    v-if="index === 2"
                    :class="$style.mobile"
                />
                <PromoAddGameHorizontal
                    v-if="index === 4"
                    :class="$style.tablet"
                />
                <PromoCategories
                    v-if="index === 7"
                    hasHeader
                />
            </template>
            <TheEnd />
        </main>
    </Layout>
</template>
<script lang="ts" setup>
import { onServerPrefetch, onMounted, computed, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { useHead } from '@unhead/vue'
import { useCategoriesStore } from '@/store/categories-store'
import { useHomepageStore, type MainCategoryListZipType } from './homepage-store'
import Layout from '@/layouts/ModernTwoColumnsLayout.vue'
import TheEnd from '@/components_new/TheEnd/TheEnd.vue'
import GameCardCarousel from '@/components_new/GameCardCarousel.vue'
import PromoCategories from '@/components_new/PromoCategories/PromoCategories.vue'
import { type MainCategoriesType } from '@/types'
import PromoSignInHorizontal from '@/components_new/PromoItems/components/PromoSignInHorizontal.vue'
import Tasks from '@/components_new/TaskItems/Tasks.vue'
import PromoInviteHorizontal from '@/components_new/PromoItems/components/PromoInviteHorizontal.vue'
import PromoSignInVertical from '@/components_new/PromoItems/components/PromoSignInVertical.vue'
import PromoInviteVertical from '@/components_new/PromoItems/components/PromoInviteVertical.vue'
import AdBlock from '@/components_new/AdBlock.vue'
import PromoAddGameHorizontal from '@/components_new/PromoItems/components/PromoAddGameHorizontal.vue'
import PromoAddGameHorizontalShort from '@/components_new/PromoItems/components/PromoAddGameHorizontalShort.vue'
import { unzipShortGame } from '@/utils/helpers'
import { useConfig } from '@/composables/config'
import { useI18nAddon } from '@/i18n/i18n'

const categoriesStore = useCategoriesStore()
const homepageStore = useHomepageStore()
const { currentLocale } = useI18nAddon()

const { categoriesWithGames } = storeToRefs(homepageStore)

const { VUE_APP_HOST_URL } = useConfig()
useHead({
    link: [
        {
            rel: 'canonical',
            href: VUE_APP_HOST_URL,
        },
    ],
})

function prepareCategories(categories: MainCategoryListZipType[]) {
    return categories.map((category, index) => {
        const isBig = index % 2 === 0 && index > 0
        const firstItemBig = index === 0

        let games = category.games?.map(unzipShortGame)
        if (!isBig && games) {
            if ((firstItemBig && games.length % 2 === 0) || (!firstItemBig && games.length % 2 === 1)) {
                games = games.slice(0, -1)
            }
        }
        return {
            id: category.id,
            title: category.title,
            location: category.location,
            games,
            isBig,
            firstItemBig,
        }
    })
}

const placeholderCategories = computed((): MainCategoryListZipType[] => {
    return categoriesStore.contentCategoriesList.length > 0
        ? categoriesStore.contentCategoriesList.map((category) => ({
              // map contentCategoriesList to ensure that no "games" field is present so caroursel will be dummy
              id: category.id,
              title: category.title,
              location: category.location,
          }))
        : Array(5)
              .fill(null)
              .map((_, index) => ({
                  id: `placeholder-${index}` as MainCategoriesType,
                  title: '\u00A0', // non-breaking space to preserve title space
                  location: undefined,
              }))
})

const realCategories = computed(() => categoriesWithGames.value[currentLocale.value])

const preparedCategories = computed(() => prepareCategories(realCategories.value || placeholderCategories.value))

// show ad only when data is loaded, otherwise it will soon remounted and re-requested again.
const showAd = computed(() => !!realCategories.value)

onServerPrefetch(async () => {
    await Promise.all([categoriesStore.setMainData(), homepageStore.loadCategoriesWithGames()])
})

onMounted(async () => {
    await Promise.all([categoriesStore.setMainData(), homepageStore.loadCategoriesWithGames()])
})

watch(currentLocale, async () => {
    await Promise.all([categoriesStore.setMainData(), homepageStore.loadCategoriesWithGames()])
})
</script>
<style module>
.page {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

/*
    Style for empty Ad block.
    Cannot not be just "display: none" because Ad container needs to be visible to react on size change.
*/
.emptyRow {
    margin-top: -24px;
}

.mobile,
.mobilePortrait,
.tablet,
.tabletPlus {
    display: none;
}

@media (--mobile) {
    .mobile,
    .mobilePortrait {
        display: block;
    }
}

@media (--mobile-landscape) {
    .mobilePortrait {
        display: none;
    }
}

@media (--tablet-plus) {
    .tablet,
    .tabletPlus {
        display: block;
    }
}

@media (--desktop-small-plus) {
    .tablet {
        display: none;
    }
}
</style>
